export const professions = [
  {
    label: "Administração / Contabilidade",
    value: "Administração / Contabilidade",
  },
  { label: "Arquitetura / Design", value: "Arquitetura / Design" },
  {
    label: "Bancos ou instituições financeiras",
    value: "Bancos ou instituições financeiras",
  },
  {
    label: "Concessionárias de automóveis",
    value: "Concessionárias de automóveis",
  },
  { label: "Direito", value: "Direito" },
  { label: "Ecommerce", value: "Ecommerce" },
  { label: "Economia", value: "Economia" },
  { label: "Educação", value: "Educação" },
  {
    label: "Empresa de tecnologia / Serviços Digitais",
    value: "Empresa de tecnologia / Serviços Digitais",
  },
  { label: "Fintechs", value: "Fintechs" },
  { label: "Indústria Alimentícia", value: "Indústria Alimentícia" },
  { label: "Indústria Automobilística", value: "Indústria Automobilística" },
  {
    label: "Indústria de Construção Civil",
    value: "Indústria de Construção Civil",
  },
  { label: "Indústria Farmacêutica", value: "Indústria Farmacêutica" },
  { label: "Indústria Têxtil/Moda", value: "Indústria Têxtil/Moda" },
  { label: "Locadoras de veículos", value: "Locadoras de veículos" },
  { label: "Marketing", value: "Marketing" },
  { label: "Pesquisa de mercado", value: "Pesquisa de mercado" },
  { label: "Publicidade e propaganda", value: "Publicidade e propaganda" },
  { label: "Rádio", value: "Rádio" },
  { label: "Restaurantes", value: "Restaurantes" },
  { label: "Saúde", value: "Saúde" },
  { label: "Seguros", value: "Seguros" },
  { label: "Serviços de Estética", value: "Serviços de Estética" },
  { label: "Serviços de Transporte", value: "Serviços de Transporte" },
  { label: "Setor imobiliário", value: "Setor imobiliário" },
  { label: "Telecom", value: "Telecom" },
  { label: "Televisão", value: "Televisão" },
  { label: "Turismo", value: "Turismo" },
  { label: "Varejo", value: "Varejo" },
  { label: "Outro", value: "Outro" },
];
